<template>
  <div class="profile-map-wrapper">
    <AppNavButton class="profile-map-close-icon" icon="close" to="/profile" />
    <WorldMap class="profile-map" />
    <div class="profile-map-stats container-s">
      <p class="profile-map-stats-title">
        {{ $t('profile.way-to-wander') }} {{ user.firstName }}.
      </p>
      <SettingsCards :data="displayedStats" style="width: 100%" />
    </div>
  </div>
</template>

<script>
import SettingsCards from '@/components/common/SettingsCards.vue'
import WorldMap from '@/components/profile/WorldMap.vue'
import AppNavButton from '../../components-rf/AppNavButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'ProfileMap',
  components: {
    SettingsCards,
    WorldMap,
    AppNavButton,
  },
  computed: {
    ...mapState('UserModule', ['user', 'travelStats']),
    ...mapState('StickerModule', ['stickers']),
    ...mapState('TravelerModule', ['travelers']),
    ...mapState('TitleModule', ['titles']),
    ...mapState('BookingModule', ['completedTrips']),
    displayedStats() {
      const { totalTrips, milesTraveled, countriesVisited } = this.travelStats
      return [
        { label: `${this.$t('profile.trips')}:`, value: totalTrips },
        {
          label: `${this.$t('profile.miles')}:`,
          value: Math.round(milesTraveled),
        },
        { label: `${this.$t('profile.countries')}:`, value: countriesVisited },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.profile-map-wrapper {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  // background: var(--color-secondary);
}
.profile-map-close-icon {
  position: relative;
  z-index: 2;
  justify-self: flex-end;
  margin: 1rem;
}
.profile-map {
  position: fixed;
  height: 100vh;
  width: 100vw;
}
.profile-map-stats {
  z-index: 2;
  position: relative;
  justify-self: center;
  align-self: flex-end;
  margin-bottom: 2rem;
  display: grid;
  gap: 1rem;
  justify-items: center;
}
.profile-map-stats-title {
  font-family: GTSuper;
  color: white;
  text-align: center;
  font-size: 2rem;
  @include s {
    font-size: 2.75rem;
  }
}
</style>
