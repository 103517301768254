<template>
  <div id="map" @click="$emit('click')">
    <GmapMap
      :center="center"
      :zoom="2.4"
      class="x-map"
      :options="mapOptions"
      style="width: 100%; height: 100%"
    >
      <GmapInfoWindow
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      ></GmapInfoWindow>
      <GmapMarker
        v-for="(city, index) in cities"
        :key="index"
        :icon="getMarker(index)"
        :position="city.position"
        :clickable="true"
        :draggable="false"
        @click="selectCity(city, index)"
      >
      </GmapMarker>
    </GmapMap>
    <slot></slot>
  </div>
</template>

<script>
import { mapOptions } from './WorldMapOptions.js'
import { mapActions, mapState } from 'vuex'
import marker from '@/assets/map-marker.svg'
import markerActive from '@/assets/map-marker-active.svg'

export default {
  name: 'WorldMap',
  props: {
    isSimple: Boolean,
  },
  created() {
    this.getCompletedBookings()
  },
  data() {
    return {
      mapOptions,
      center: {
        lat: 40.866667,
        lng: 34.566667,
      },
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
        padding: '25rem',
      },
      marker,
      markerActive,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMarkerIndex: null,
    }
  },
  methods: {
    ...mapActions('BookingModule', ['getCompletedBookings']),
    selectCity(city, index) {
      this.infoWindowPos = city.position
      // <div style="padding-left: 4px; padding-bottom: 4px;">
      this.infoOptions.content = `
      <div style="display: grid; gap: 5px; padding: 16px">
        <p style="text-align: center; font-size: 22px; font-style: italic; font-family: SpaceMono">${city.iata}</p>
        <p style="text-align: center; font-size: 16px; color: var(--color-secondary); font-family: DDin;">${city.name}</p>
      </div>
      `

      if (this.currentMarkerIndex === index)
        this.infoWinOpen = !this.infoWinOpen
      else {
        this.infoWinOpen = true
        this.currentMarkerIndex = index
      }
    },
    getMarker(index) {
      if (this.currentMarkerIndex === index) return this.markerActive
      return this.marker
    },
  },

  computed: {
    ...mapState('BookingModule', ['completedBookings']),
    cities() {
      return this.completedBookings
        .map(segment => segment.arrivalCity)
        .filter(city => city.pb_long && city.lat)
        .map(city => ({
          position: {
            lat: parseFloat(city.lat),
            lng: parseFloat(city.pb_long),
          },
          name: `${city.name}, ${city.region.country.name}`,
          iata: city.iataCode || city.region.isoCode,
        }))
    },
  },
}
</script>

<style scoped lang="scss">
.x-map {
  position: absolute;
  height: 100vh;
  width: 100vw;
}
</style>
